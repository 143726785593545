import React from 'react';
import styled from 'styled-components';
import {message} from 'antd';
import GenericForm from 'rev.sdk.js/Generic/Form';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import AdminSelectProductWidget from '../../Generators/AdminResource/AdminSelectProductWidget';
import {showAdminDownloadExcelModal} from '../../Components/AdminDownloadExcelModal';
import ExportHelper from '../../TbaExportHelper';
import {COURSE_INTERNAL_LABELS} from '../../Utils/ProductUtil';
import {
  getUserSubstateLabel,
  getUserStateLabel,
  getUserSexLabel,
  getUserInformToLabel,
} from '../../Utils/UserUtil';
import * as LegacyUtil from '../../Utils/LegacyUtil';

const EXPORT_COLLECTION = 'product';
const EXPORT_TITLE_DEFAULT = '課程資料匯出';
const EXPORT_FIELDS = [
  {key: 'serial_number', name: '課程編號'},
  {key: 'internal_label', name: '課程類別'},
  {key: 'name', name: '課程名稱'},
  {key: 'expertises', name: '特別領域分類'},
  {key: 'people_host', name: '主持人'},
  {key: 'people_lecturer', name: '講師'},
  {key: 'session_date', name: '日期'},
  {key: 'session_time', name: '時間'},
  {key: 'session_place', name: '地點'},
  {key: 'members_length', name: '實體應到人數'},
  {key: 'members_attend_length', name: '實體實到人數'},
  {key: 'online_members_length', name: '線上應到人數'},
  {key: 'online_members_attend_length', name: '線上實到人數'},
  {key: 'tba_hours', name: '進修時數'},
  {key: 'execution_note', name: '執行情形'},
];
const USER_EXPORT_FIELDS = [
  {key: 'serial_number', name: '流水號'},
  {key: 'name', name: '姓名'},
  {key: 'birthday', name: '生日'},
  {key: 'state', name: '狀態'},
  {key: 'substate', name: '次狀態'},
  {key: 'SEX', name: '性別'},
  {key: 'id_card_number', name: '身分證字號'},
  {key: 'phone', name: '手機'},
  {key: 'inform_address', name: '寄送地址'},
  {key: 'email', name: 'email'},
  {key: 'EMAIL2', name: '備用email'},
  {key: 'mail_address', name: '通訊地址'},
  {key: 'company_current_name', name: '現在事務所名稱'},
  {key: 'company_address', name: '現在事務所地址'},
  {key: 'company_phone', name: '現在事務所電話'},
  {key: 'company_fax', name: '現在事務所傳真'},
  {key: 'is_vegetarian', name: '素食'},
];

async function transformQuery(formData) {
  const {
    serial_number,
    name,
    product,
    internal_label,
    session_date_start,
    session_date_end,
    tba_hours_start,
    tba_hours_end,
  } = formData;

  const query = {
    archived: {$ne: true},
  };

  if (!!serial_number) {
    query['serial_number'] = {$regex: serial_number};
  }

  if (!!name) {
    query['name'] = {$regex: name};
  }

  if (!!product) {
    const p = await JStorage.fetchOneDocument('product', {
      _id: {$oid: product},
    });

    if (
      p.type === 'n_in_1' &&
      p.n_in_1_products &&
      Array.isArray(p.n_in_1_products) &&
      p.n_in_1_products.length > 0
    ) {
      query['$or'] = [
        {_id: {$oid: product}},
        ...p.n_in_1_products.map((id) => ({_id: {$oid: id}})),
      ];
    } else {
      query['_id'] = {$oid: product};
    }
  }

  if (internal_label !== '無') {
    query['internal_label'] = {$in: [internal_label]};
  }

  if (!!session_date_start) {
    query['session.date'] = {$gte: session_date_start};
  }

  if (!!session_date_end) {
    if (query['session.date']) {
      query['session.date'] = {
        ...query['session.date'],
        $lt: session_date_end,
      };
    } else {
      query['session.date'] = {
        ...query['session.date'],
        $lt: session_date_end,
      };
    }
  }

  if (tba_hours_start === 0 || !!tba_hours_start) {
    query['tba_hours'] = {$gte: tba_hours_start};
  }

  if (!!tba_hours_end) {
    if (query['tba_hours']) {
      query['tba_hours'] = {
        ...query['tba_hours'],
        $lt: tba_hours_end,
      };
    } else {
      query['tba_hours'] = {
        $lt: tba_hours_end,
      };
    }
  }

  return query;
}

function transformProjection() {
  const projection = {
    serial_number: 1,
    internal_label: 1,
    name: 1,
    people: 1,
    unit: 1,
    session: 1,
    stock_sold_amount: 1,
    execution_note: 1,
    tba_hours: 1,
    expertises: 1,
    type: 1,
    n_in_1_products: 1,
  };

  return projection;
}

function generateExportFields(selectAllFields, fields) {
  return selectAllFields
    ? EXPORT_FIELDS
    : EXPORT_FIELDS.filter(
        (obj) => fields.findIndex((key) => key === obj.key) > -1,
      );
}

function generateMemberExportFields(selectAllFields, fields) {
  return selectAllFields
    ? USER_EXPORT_FIELDS
    : USER_EXPORT_FIELDS.filter(
        (obj) => fields.findIndex((key) => key === obj.key) > -1,
      );
}

function generateProductAndMemberRows({
  records,
  selectedFields,
  memberSelectedFields,
  attendStatus,
  helper,
}) {
  const results = [];

  for (let i = 0; i < records.length; i++) {
    const r = records[i];
    const signForm = helper.getLatestSignForm(r.id);

    const productSingleRow = selectedFields.map((f, idx) => {
      const session = r.session || {};
      const people = session.people || [];

      if (f.key === 'serial_number') {
        return `${f.name}：${r.serial_number || ''}\n`;
      } else if (f.key === 'internal_label') {
        return `${f.name}：${r.internal_label || ''}\n`;
      } else if (f.key === 'name') {
        return `${f.name}：${r.name || ''}\n`;
      } else if (f.key === 'expertises') {
        let text = '';

        if (Array.isArray(r.expertises) && r.expertises.length > 0) {
          for (let i = 0; i < r.expertises.length; i++) {
            text += `${r.expertises[i]}${
              i + 1 !== r.expertises.length ? '、' : ''
            }`;
          }
        }

        return `${f.name}：${text}\n`;
      } else if (f.key === 'people_lecturer') {
        const lecturers = people
          .filter((p) => p.type === '講師')
          .map((p) => ({user: p.user, name: p.name}));

        return `${f.name}：${lecturers.map(
          (p, i) =>
            `${helper.getUser(p.user).name || ''}${
              i + 1 === lecturers.length ? '' : '、'
            }` || `${p.name || ''}${i + 1 === lecturers.length ? '' : '、'}`,
        )}\n`;
      } else if (f.key === 'unit') {
        return `${f.name}：${r.unit || 0}\n`;
      } else if (f.key === 'session_date') {
        return `${f.name}：${session.date || ''}\n`;
      } else if (f.key === 'session_time') {
        return `${f.name}：${session.start_time || ''}-${
          session.end_time || ''
        }\n`;
      } else if (f.key === 'session_place') {
        return `${f.name}：${
          session.place || ''
        } ${LegacyUtil.getProductMeetingRoomsLabel(r)}\n`;
      } else if (f.key === 'members_length') {
        const members_length =
          signForm.members && Array.isArray(signForm.members)
            ? signForm.members.length
            : 0;
        return `${f.name}：${members_length || 0}人\n`;
      } else if (f.key === 'members_attend_length') {
        const members_attend_length =
          signForm.members && Array.isArray(signForm.members)
            ? signForm.members.filter((member) => member.is_attend).length
            : 0;

        return `${f.name}：${members_attend_length || 0}人\n`;
      } else if (f.key === 'online_members_length') {
        const online_members_length =
          signForm.online_members && Array.isArray(signForm.online_members)
            ? signForm.online_members.length
            : 0;
        return `${f.name}：${online_members_length || 0}人\n`;
      } else if (f.key === 'online_members_attend_length') {
        const online_members_attend_length =
          signForm.online_members && Array.isArray(signForm.online_members)
            ? signForm.online_members.filter((member) => member.is_attend)
                .length
            : 0;

        return `${f.name}：${online_members_attend_length || 0}人\n`;
      } else if (f.key === 'execution_note') {
        return `${f.name}：${r.execution_note || ''}\n`;
      }

      return '';
    });

    results.push([productSingleRow.toString().replace(/,/g, '')]);
    results.push([...memberSelectedFields.map((f) => f.name)]);

    const isPaymentStatusSuccessOrders = r.orders.filter(
      (o) =>
        o.payment_status === 'success' &&
        o.status !== 'canceled' &&
        o.status !== 'return_applied' &&
        o.status !== 'return_completed' &&
        o.status !== 'time_canceled',
    );

    for (let v = 0; v < isPaymentStatusSuccessOrders.length; v++) {
      const o = isPaymentStatusSuccessOrders[v];

      const returnOrderArray = [
        {key: 'owner', name: 'owner'},
        ...memberSelectedFields,
      ].map((f, idx) => {
        const buyerInfo = helper.getBuyerInfo(o.owner);
        const cct = helper.getCompany(buyerInfo.company_current);

        if (f.key === 'serial_number') {
          return `${v + 1}`;
        } else if (f.key === 'name') {
          return `${o.buyer_name || ''}`;
        } else if (f.key === 'birthday') {
          return `${(buyerInfo.birthday || '').split('T')[0]}`;
        } else if (f.key === 'state') {
          return `${getUserStateLabel(buyerInfo.state) || ''}`;
        } else if (f.key === 'substate') {
          return `${getUserSubstateLabel(buyerInfo.substate) || ''}`;
        } else if (f.key === 'SEX') {
          return `${getUserSexLabel(buyerInfo.SEX) || ''}`;
        } else if (f.key === 'id_card_number') {
          return `${buyerInfo.id_card_number}`;
        } else if (f.key === 'phone') {
          return `${o.buyer_phone}`;
        } else if (f.key === 'inform_address') {
          const informToLabel = `${
            getUserInformToLabel(buyerInfo.INFORM_TO) || ''
          }`;

          const address = (() => {
            const informTo = buyerInfo.INFORM_TO;

            if (informTo === 1) {
              //事務所
              const company_address = `${
                buyerInfo.company_zip_code || cct.address_zip_code || ''
              } ${buyerInfo.company_city || cct.address_city || ''} ${
                buyerInfo.company_detail || cct.address_detail || ''
              }`;

              return company_address;
            } else if (informTo === 2) {
              //律師法第26條陳明收受送達處所
              const mail_address = `${buyerInfo.mail_address_zip_code || ''} ${
                buyerInfo.mail_address_city || ''
              } ${buyerInfo.mail_address_detail || ''}`;

              return mail_address;
            } else if (
              informTo === 3 ||
              informTo === undefined ||
              informTo === null
            ) {
              //不寄
              return '';
            }

            return '';
          })();

          return `[${informToLabel}] ${address}`;
        } else if (f.key === 'email') {
          return `${buyerInfo.email}`;
        } else if (f.key === 'EMAIL2') {
          return `${buyerInfo.EMAIL2 || ''}`;
        } else if (f.key === 'mail_address') {
          return `${buyerInfo.mail_address_zip_code || ''} ${
            buyerInfo.mail_address_city || ''
          } ${buyerInfo.mail_address_detail || ''}`;
        } else if (f.key === 'company_current_name') {
          return `${cct.CONAME || ''}`;
        } else if (f.key === 'company_address') {
          return `${buyerInfo.company_zip_code || cct.address_zip_code || ''} ${
            buyerInfo.company_city || cct.address_city || ''
          } ${buyerInfo.company_detail || cct.address_detail || ''}`;
        } else if (f.key === 'company_phone') {
          return `${buyerInfo.company_phone || cct.COPHONE1 || ''}`;
        } else if (f.key === 'company_fax') {
          return `${buyerInfo.company_fax || cct.COFAX1 || ''}`;
        } else if (f.key === 'is_vegetarian') {
          return `${
            o.vegetarians && o.vegetarians.length > 0
              ? o.vegetarians[0].is_vegetarian
                ? '是'
                : '否'
              : '否'
          }`;
        }
        return o[f.key];
      });

      //attend status
      if (attendStatus === 'all') {
        returnOrderArray.shift();
        results.push(returnOrderArray);
      } else {
        let ownerList;

        if (attendStatus === 'is_attend') {
          ownerList = (signForm.members || [])
            ?.filter((m) => m.is_attend)
            .map((m) => m.owner);
        } else if (attendStatus === 'is_not_attend') {
          ownerList = (signForm.members || [])
            ?.filter((m) => !m.is_attend)
            .map((m) => m.owner);
        } else if (attendStatus === 'online_is_attend') {
          ownerList = (signForm.online_members || [])
            ?.filter((m) => m.is_attend)
            .map((m) => m.owner);
        } else if (attendStatus === 'online_is_not_attend') {
          ownerList = (signForm.online_members || [])
            ?.filter((m) => !m.is_attend)
            .map((m) => m.owner);
        }

        const isInOwnerList = ownerList.some((o) => o === returnOrderArray[0]);
        const hasSerialNumber =
          selectedFields.findIndex((f) => f.key === 'serial_number') > -1;

        if (isInOwnerList) {
          if (hasSerialNumber) {
            const index = ownerList.findIndex((o) => o === returnOrderArray[0]);
            returnOrderArray[0] = `${index + 1}`;
            returnOrderArray.splice(1, 1);
          } else {
            returnOrderArray.shift();
          }

          results.push(returnOrderArray);
        }
      }
    }

    results.sort((a, b) => a[0] - b[0]);
    results.push([]);
  }

  return results;
}

function generateProductRows({records, selectedFields, helper}) {
  const header = [...selectedFields.map((f) => f.name)];
  const productRows = [
    ...records.map((r, index) => {
      const signForm = helper.getLatestSignForm(r.id);

      const returnValues = selectedFields.map((f) => {
        const session = r.session || {};
        const people = session.people || [];

        if (f.key === 'internal_label') {
          return r.internal_label || '';
        } else if (f.key === 'people_host') {
          const hosts = people
            .filter((p) => p.type === '主持人')
            .map((p) => ({user: p.user, name: p.name}));

          return hosts.map((p) => helper.getUser(p.user).name || p.name);
        } else if (f.key === 'people_lecturer') {
          const lecturers = people
            .filter((p) => p.type === '講師')
            .map((p) => ({user: p.user, name: p.name}));

          return lecturers.map((p) => helper.getUser(p.user).name || p.name);
        } else if (f.key === 'expertises') {
          let text = '';

          if (Array.isArray(r.expertises) && r.expertises.length > 0) {
            for (let i = 0; i < r.expertises.length; i++) {
              text += `${r.expertises[i]}${
                i + 1 !== r.expertises.length ? '、' : ''
              }`;
            }
          }

          return text;
        } else if (f.key === 'session_date') {
          return session.date || '';
        } else if (f.key === 'session_time') {
          return `${session.start_time || ''}-${session.end_time || ''}`;
        } else if (f.key === 'session_place') {
          return `${
            session.place || ''
          } ${LegacyUtil.getProductMeetingRoomsLabel(r)}`;
        } else if (f.key === 'members_length') {
          return (signForm.members || []).length;
        } else if (f.key === 'members_attend_length') {
          return (signForm.members || []).filter((m) => m.is_attend).length;
        } else if (f.key === 'online_members_length') {
          return (signForm.online_members || []).length;
        } else if (f.key === 'online_members_attend_length') {
          return (signForm.online_members || []).filter((m) => m.is_attend)
            .length;
        }

        return r[f.key];
      });

      return [...returnValues];
    }),
  ];

  const results = [header, ...productRows];

  return results;
}

function AdminCourseExport(props) {
  const rjsfProps = {
    widgets: {
      'admin-select-product-widget': AdminSelectProductWidget,
    },
  };

  return (
    <Wrapper>
      <GenericForm
        instance={null}
        rjsfProps={rjsfProps}
        schema={{
          title: '',
          type: 'object',
          properties: {
            title: {
              type: 'string',
              title: '報表名稱',
              default: EXPORT_TITLE_DEFAULT,
            },
            serial_number: {
              type: 'string',
              title: '課程編號',
            },
            name: {
              type: 'string',
              title: '課程名稱',
            },
            product: {
              type: 'string',
              title: '課程選擇',
            },
            internal_label: {
              type: 'string',
              title: '公會內部分類',
              default: '無',
              enum: ['無', ...COURSE_INTERNAL_LABELS],
            },
            session_date_start: {
              type: 'string',
              title: '場次日期區間（起）',
              format: 'date',
            },
            session_date_end: {
              type: 'string',
              title: '場次日期區間（迄）',
              format: 'date',
            },
            tba_hours_start: {
              type: 'number',
              title: '進修時數（起）',
            },
            tba_hours_end: {
              type: 'number',
              title: '進修時數（迄）',
            },
            selectAllFields: {
              type: 'boolean',
              title: '匯出欄位全選',
              enum: [true, false],
              enumNames: ['是', '否'],
              default: true,
            },
            fields: {
              type: 'array',
              title: '課程匯出欄位選擇',
              readOnly: true,
              default: EXPORT_FIELDS.map((field) => field.key),
              items: {
                type: 'string',
                enum: EXPORT_FIELDS.map((field) => field.key),
                enumNames: EXPORT_FIELDS.map((field) => field.name),
              },
              uniqueItems: true,
            },
            exportMemberFields: {
              type: 'boolean',
              title: '匯出會員欄位',
              enum: [true, false],
              enumNames: ['是', '否'],
              default: false,
            },
            memberSelectAllFields: {
              type: 'boolean',
              title: '會員匯出欄位全選',
              enum: [true, false],
              enumNames: ['是', '否'],
              readOnly: true,
            },
            memberFields: {
              type: 'array',
              title: '會員資料匯出欄位選擇',
              default: USER_EXPORT_FIELDS.map((field) => field.key),
              items: {
                type: 'string',
                enum: USER_EXPORT_FIELDS.map((field) => field.key),
                enumNames: USER_EXPORT_FIELDS.map((field) => field.name),
              },
              uniqueItems: true,
              readOnly: true,
            },
            attendStatus: {
              type: 'string',
              title: '出席狀態',
              default: 'all',
              enum: [
                'all',
                'is_attend',
                'is_not_attend',
                'online_is_attend',
                'online_is_not_attend',
              ],
              enumNames: [
                '全部',
                '實體已出席',
                '實體未出席',
                '線上已出席',
                '線上未出席',
              ],
              readOnly: true,
            },
          },
          dependencies: {
            selectAllFields: {
              oneOf: [
                {
                  required: ['fields'],
                  properties: {
                    selectAllFields: {enum: [false]},
                    fields: {
                      readOnly: false,
                    },
                  },
                },
              ],
            },
            exportMemberFields: {
              oneOf: [
                {
                  required: ['memberSelectAllFields'],
                  properties: {
                    exportMemberFields: {enum: [true]},
                    memberSelectAllFields: {readOnly: false, default: true},
                  },
                },
              ],
            },
            memberSelectAllFields: {
              oneOf: [
                {
                  required: ['memberFields', 'attendStatus'],
                  properties: {
                    memberSelectAllFields: {enum: [false]},
                    memberFields: {
                      readOnly: false,
                    },
                    attendStatus: {
                      readOnly: false,
                    },
                  },
                },
              ],
            },
          },
        }}
        uiSchema={{
          product: {
            'ui:widget': 'admin-select-product-widget',
          },
          session_date_start: {
            'ui:help': '格式為 yyyy-mm-dd，例如: 2024-01-01',
          },
          session_date_end: {
            'ui:help': '格式為 yyyy-mm-dd，例如: 2024-01-01',
          },
        }}
        onSubmit={async (formData) => {
          const {
            selectAllFields,
            fields,
            exportMemberFields,
            memberSelectAllFields,
            memberFields,
            attendStatus,
          } = formData;

          let query = await transformQuery(formData);
          let projection = transformProjection();
          let selectedFields = generateExportFields(selectAllFields, fields);
          let memberSelectedFields = exportMemberFields
            ? generateMemberExportFields(memberSelectAllFields, memberFields)
            : null;

          let excelProps = {
            filename: `${formData.title || EXPORT_TITLE_DEFAULT}.xlsx`,
            collection: EXPORT_COLLECTION,
            query,
            projection,
            sort: ['session.date', 'created'],
            recordsToAoa: async (records) => {
              try {
                const helper = new ExportHelper({
                  orderItemProductIds: exportMemberFields
                    ? records.map((r) => r.id)
                    : null,
                  signFormProductIds: records.map((r) => r.id),
                });

                await helper.fetchAll();

                let nextRecords = records.map((r) => {
                  const orders = helper.getOrdersByProductId(r.id);
                  return {...r, orders};
                });

                for (let i = 0; i < nextRecords.length; i++) {
                  const p = nextRecords[i];

                  const hasNIn1Products =
                    Array.isArray(p.n_in_1_products) &&
                    p.n_in_1_products.length > 0;
                  const hasOrders = p.orders.length > 0;

                  if (p.type === 'n_in_1' && hasNIn1Products && hasOrders) {
                    for (let n = 0; n < p.n_in_1_products.length; n++) {
                      const id = p.n_in_1_products[n];
                      const nIndex = nextRecords.findIndex((p) => p.id === id);

                      if (nIndex > -1) {
                        nextRecords[nIndex].orders = [
                          ...p.orders,
                          ...nextRecords[nIndex].orders,
                        ];
                      }
                    }
                  }
                }

                return exportMemberFields
                  ? generateProductAndMemberRows({
                      records: nextRecords,
                      selectedFields,
                      memberSelectedFields,
                      attendStatus,
                      helper,
                    })
                  : generateProductRows({records, selectedFields, helper});
              } catch (err) {
                console.warn(err);
                message.error('發生錯誤');
              }
            },
            updateWorkSheet: exportMemberFields
              ? (ws) => {
                  // s = start, r = row, c = col, e = end
                  const merge = [{s: {r: 0, c: 0}, e: {r: 0, c: 10}}];
                  ws['!merges'] = merge;
                }
              : null,
          };

          showAdminDownloadExcelModal({...excelProps});
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div``;

export default AdminCourseExport;
